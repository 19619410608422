import React from 'react';
import Header from '../Components/Header';
import { Container, Row, Col } from 'reactstrap'


function International() {

    return (
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "white", width: "100%", height: "100vh", margin: 0 }}>
        <Row>
            <Col>
                <Header />
            </Col>
        </Row>

        <Row style={{marginTop: 50}}>
            <Col>
                <div>
                    <p style={{color: "black", fontSize: "30px", textAlign: "center"}}>A venir bientôt</p>
                </div>
            </Col>
        </Row>

    </Container>
    )
}

export default International