import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { message } from 'antd'
import emailjs, { send } from 'emailjs-com';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


function Footer() {


    const [emailSuccess, setEmailSuccess] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    var sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('i38infos@gmail.com', 'template_qi2m81n', e.target, 'user_oJt2ouWgwHaNLR48jwM9U')
            .then((result) => {
                console.log(result.text);

                setEmailSuccess(true);
                setTimeout(() => { toggleModal(); setEmailSuccess(false) }, 4000)

            }, (error) => {
                console.log(error.text);
                setEmailError(true)
                setTimeout(() => setEmailError(false), 3000)
            });
    }


    return (

        <div className="footer" style={{ backgroundColor: "black" }}>
            <div style={{ display: "flex", justifyContent: "space-around", width: "40%", marginTop: 20 }}>
                <a href="https://www.facebook.com/i38infos/" target="_blank"><FaFacebookF style={{ color: "white", fontSize: 30, cursor: "pointer" }} /></a>
                <a href="https://twitter.com/slimanimateur?lang=fr" target="_blank"><FaTwitter style={{ color: "white", fontSize: 30, }} /></a>
                <a href="https://www.youtube.com/channel/UChKv4jmTAiNGDdnU3jz1kEQ" target="_blank"><FaYoutube style={{ color: "white", fontSize: 30, }} /></a>
                <a href="https://www.instagram.com/i38infos_webtv/" target="_blank"><FaInstagram style={{ color: "white", fontSize: 30, }} /></a>
            </div>

            <div>
                <p style={{ color: "white", fontSize: 15, marginTop: 20 }}>2020 - i38infos</p>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around", width: "80%", color: "white", marginTop: 20 }}>
                <p style={{ textAlign: "center", cursor: "pointer" }}>Tous droits réservés</p>
                <p style={{ textAlign: "center", cursor: "pointer" }}>Mentions Légales</p>
                <p style={{ textAlign: "center", cursor: "pointer" }}>Qui sommes-nous ?</p>
                <p style={{ textAlign: "center", cursor: "pointer" }} onClick={() => toggleModal()}>Nous contacter</p>
            </div>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Contactez i38infos!</ModalHeader>
                <ModalBody>
                    <div>
                        <p>Une idée ? Envie de nous faire part de quelque chose ? Envie de participer à un Live ? Contactez-nous via ce formulaire.</p>
                    </div>

                    <div>
                        <form onSubmit={sendEmail}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input name="user_email" placeholder="adresse mail" />
                            </InputGroup>
                            <InputGroup style={{ marginTop: 10 }}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><FontAwesomeIcon icon={faEnvelope} /></InputGroupText>
                                </InputGroupAddon>
                                <Input name="message" type="textarea" placeholder="votre message" />
                            </InputGroup>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button type="submit" disabled={emailSuccess ? true : false} style={{ marginTop: 10, marginBottom: 20 }} outline color={emailSuccess ? "success" : emailError ? "warning": "danger"}>{emailSuccess ? "Votre mail a été envoyé" : emailError ? "Petite erreur, veuillez réessayer": "Envoyer"}</Button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={emailSuccess ? true : false} onClick={toggleModal}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Footer