import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer'
import ReactPlayer from "react-player"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import emailjs, { send } from 'emailjs-com';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { message } from 'antd'


function Home() {

    //https://www.googleapis.com/youtube/v3/search?key={your_key_here}&channelId={channel_id_here}&part=snippet,id&order=date&maxResults=20

    const URL = "https://www.googleapis.com/youtube/v3/search?"
    const API = "AIzaSyC5iiia1vvu1LfkCSBsrD9EDnpivtFr6KY"
    const channelID = "UChKv4jmTAiNGDdnU3jz1kEQ"

    const [lastVideo, setLastVideo] = useState("")
    const [last3videos, setLast3Videos] = useState([])

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipContact, setTooltipContact] = useState(false)
    const [email, setEmail] = useState("");
    const [text, setText] = useState("")
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggleContact = () => setTooltipContact(!tooltipContact);

    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    var sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('i38infos@gmail.com', 'template_qi2m81n', e.target, 'user_oJt2ouWgwHaNLR48jwM9U')
            .then((result) => {
                console.log(result.text);

                setEmailSuccess(true);
                setTimeout(() => { toggleModal(); setEmailSuccess(false) }, 4000)

            }, (error) => {
                console.log(error.text);
                setEmailError(true)
                setTimeout(() => { setEmailError(false) }, 3000)
            });
    }

    useEffect(() => {

        var retrieveLastVideo = async () => {
            const jsonRaw = await fetch(URL + "key=" + API + "&channelId=" + channelID + "&part=snippet,id&order=date" + "&maxResults=6")
            const json = await jsonRaw.json()


            if (json) {
                setLastVideo("https://www.youtube.com/embed/" + json.items[0].id.videoId)
                setLast3Videos(json.items.map(item => item))
            } else {
                setLastVideo("https://www.youtube.com/embed/70QunpbQrAw")

            }

        }



        retrieveLastVideo()

    }, [])

    return (
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0, width: "100%", margin: 0, }}>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>

            <Row style={{ margin: 0, display: "flex", justifyContent: "space-around", backgroundColor: "white" }}>
                <Col className="last-live" xs="12" md="6" style={{ paddingBottom: 20, marginTop: 10, borderRadius: 20, borderColor: "white", borderWidth: 5, borderStyle: "solid", }}>
                    <div style={{ paddingTop: 20 }}>
                        <p style={{ textAlign: "center", fontSize: 30, marginTop: 10, fontWeight: "bold", color: "black", textTransform: "uppercase" }}>Notre dernier live</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: 20, flexDirection: "column" }}>

                        <iframe width="80%" height="300vh" src={lastVideo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                    </div>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        <div style={{ width: "30%", borderBottomStyle: "solid", borderColor: "black", borderWidth: 3 }}>

                        </div>
                    </div>

                </Col>

                <Col xs="12" md="6" style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>

                    <Row className="other-gradient" style={{ color: "black", display: "flex", flexDirection: "column", justifyContent: "center", borderWidth: 5, borderStyle: "solid", borderColor: "white", borderBottomStyle: "solid", borderRadius: 20, paddingTop: 10, paddingBottom: 10 }}>
                        <p style={{ textAlign: "left", paddingLeft: 10 }}><strong style={{ fontFamily: "Fredoka One" }}>i38 infos</strong>, LA chaîne YouTube qui traite l’information et qui met en avant ses invités à travers plusieurs émissions.</p>

                        <p style={{ paddingLeft: 10 }}><strong>On C pas tout dit </strong> : L'émission où l'on met en avant nos invités tout en essayant de comprendre leurs métiers, leurs passions (sport, danse, etc)</p>

                        <p style={{ paddingLeft: 10 }}><strong>Doc Francisco </strong>: L'émission avec le Docteur Xavier Francisco</p>
                        <p style={{ paddingLeft: 10 }}>L'émission politique, sport, etc</p>

                        <p style={{ paddingLeft: 10 }}><strong style={{color: "blue"}}>Accèdez</strong> ou <strong style={{color: "red"}}>Abonnez-vous</strong> à notre chaine en cliquant directement sur le bouton ci-dessous !</p>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div class="g-ytsubscribe" data-channelid="UChKv4jmTAiNGDdnU3jz1kEQ" data-layout="full" data-count="default">
                            </div>
                        </div>
                    </Row>

                    <Row className="other-gradient" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 5, borderStyle: "solid", borderColor: "white", borderBottomStyle: "solid", borderRadius: 20, marginTop: 5, paddingTop: 15, paddingBottom: 10 }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <FontAwesomeIcon icon={faCalendarAlt} style={{ color: "black", height: "10%", width: "10%"  }} id="TooltipExample" />
                            <p style={{paddingTop: 7}}>Des émissions régulières en direct, des débats, de l'actu, du sport, et bien plus !</p>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <FontAwesomeIcon onClick={() => toggleModal()} icon={faEnvelope} style={{ color: "black", height: "20%", width: "20%" }} id="Contactez-nous" />
                            <p>Contactez-nous !</p>
                        </div>


                        <Modal isOpen={modal} toggle={toggleModal}>
                            <ModalHeader toggle={toggleModal}>Contactez i38infos!</ModalHeader>
                            <ModalBody>
                                <div>
                                    <p>Une idée ? Envie de nous faire part de quelque chose ? Envie de participer à un Live ? Contactez-nous via ce formulaire.</p>
                                </div>

                                <div>
                                    <form onSubmit={sendEmail}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>@</InputGroupText>
                                            </InputGroupAddon>
                                            <Input name="user_email" placeholder="adresse mail" />
                                        </InputGroup>
                                        <InputGroup style={{ marginTop: 10 }}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText><FontAwesomeIcon icon={faEnvelope} /></InputGroupText>
                                            </InputGroupAddon>
                                            <Input name="message" type="textarea" placeholder="votre message" />
                                        </InputGroup>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button type="submit" disabled={emailSuccess ? true : false} style={{ marginTop: 10, marginBottom: 20 }} outline color={emailSuccess ? "success" : emailError ? "warning" : "danger"}>{emailSuccess ? "Votre mail a été envoyé" : emailError ? "Petite erreur, veuillez réessayer" : "Envoyer"}</Button>
                                        </div>
                                    </form>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" disabled={emailSuccess ? true : false} onClick={toggleModal}>Annuler</Button>
                            </ModalFooter>
                        </Modal>

                    </Row>

                </Col>
            </Row>


            <Row className="last-lives" style={{ borderRadius: 20, marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 15 }}>

                <Col>


                    <Row>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ paddingTop: 30, fontWeight: "bold", color: "black", fontSize: 20, marginBottom: "20px", textAlign: "center", textTransform: "uppercase" }}>Les dernières vidéos</p>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ display: "flex", justifyContent: "space-around", paddingBottom: 50 }}>




                        {
                            last3videos.map((video, i) => {

                                const url = "https://www.youtube.com/embed/" + video.id.videoId

                                if (i > 0) {
                                    return (

                                        <div key={i} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <iframe width="280" height="157" src={url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            <p style={{ color: "black", alignSelf: "center", backgroundColor: "white", fontWeight: "bold", textAlign: "center", width: "280px", height: 30 }} className="video-title">{video.snippet.description}</p>
                                        </div>

                                    )
                                }


                            })
                        }


                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    )
}

export default Home