import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; 
import Home from './Screens/Home';
import Sports from './Screens/Sports';
import National from './Screens/National';
import International from './Screens/International';
import Politique from "./Screens/Politique";
import Debats from './Screens/Debats'
import Partenaires from './Screens/Partenaires'

function App() {
  return (

    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/sports" component={Sports} />
        <Route path="/politique" component={Politique} />
        <Route path="/national" component={National} />
        <Route path="/international" component={International} />
        <Route path="/debats" component={Debats} />
        <Route path="/partenaires" component={Partenaires} />
      </Switch>
    </Router>
    
  );
}

export default App;
