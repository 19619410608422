import React, { useState } from 'react';
import Header from '../Components/Header';
import { Container, Row, Col } from 'reactstrap'
import Footer from '../Components/Footer'


function Partenaires() {



    const [partnersFirstCol, setPartnersFirstCol] = useState([
        {
            nom: "ville de bourgoin",
            image: "IMG_0129.jpeg",
            alt: "ville de bourgoin jallieu",
            height: "90px",
            width: "250px",
            link: "https://youtu.be/ZeKOoDQK3oE"
        },

        {
            nom: "isolation rhones alpes",
            image: "IMG_0631.jpeg",
            alt: "isolation rhones alpes",
            height: "90px",
            width: "250px",
            link: "https://youtu.be/-F6Itu38zkw"
        },

        {
            nom: "mc slim",
            image: "IMG_3533.jpeg",
            alt: "mc slim",
            height: "90px",
            width: "250px",
            link: "http://www.slimanimateur.com"
        }
        ,
        {
            nom: "Arxama",
            image: "arxama.png",
            alt: "arxama",
            height: "90px",
            width: "250px",
            link: "https://www.arxama.com"
        }
    ])

    const [partnersSecondCol, setPartnersSecondCall] = useState([
        {
            nom: "adp",
            image: "adp.jpg",
            alt: "ADP",
            height: "200px",
            width: "200px",
            link: "http://i38infos.fr"
        },

        {
            nom: "power animation",
            image: "IMG_4090.jpeg",
            alt: "power animation",
            height: "200px",
            width: "200px",
            link: "http://i38infos.fr"
        },
        {
            nom: "espace midinette",
            image: "midinette.JPG",
            alt: "espace midinette",
            height: "200px",
            width: "200px",
            link: "https://www.espacemidinette.fr"
        },
        {
            nom: "just mode",
            image: "justmode.JPG",
            alt: "just mode",
            height: "200px",
            width: "200px",
            link: "https://www.just-mode.fr"
        }
    ])

    const [partnersThirdCol, setPartnersThirdCol] = useState([
        

        {
            nom: "pho la riviere des parfums",
            image: "riviere2.jpg",
            alt: "pho la riviere des parfums",
            height: "200px",
            width: "200px",
            link: "https://youtu.be/rwsVQ_oALTc"
        },

        {
            nom: "bravis immobilier",
            image: "bravis.jpg",
            alt: "bravis immobilier",
            height: "150px",
            width: "200px", 
            link: "https://www.bravis.fr"
        },
        {
            nom: "dt studio",
            image: "dtstudio.JPG",
            alt: "dt studio",
            height: "200px",
            width: "200px",
            link: "https://www.dt-studio.fr "
        },
        {
            nom: "brf",
            image: "brf.png",
            alt: "brf",
            height: "200px",
            width: "200px",
            link: "http://www.bati-renov-facades.fr/"
        }
    ])

    const [partenrsFourthCol, setPartnersFourthCol] = useState([
        {
            nom: "les abrets en dauphiné",
            image: "abrets.png",
            alt: "les abrets en dauphiné",
            height: "150px",
            width: "200px", 
            link: "https://www.les-abrets-en-dauphine.fr"
        },

        
        {
            nom: "le traditionnel",
            image: "traditionnel.jpg",
            alt: "le traditionnel",
            height: "150px",
            width: "200px", 
            link: "https://youtu.be/lSlDs1Q4m94"
        }, 
        {
            nom: "biodou",
            image: "biodou.JPG",
            alt: "biodou",
            height: "200px",
            width: "200px",
            link: "https://www.facebook.com/Biodou38/"
        },
        {
            nom: "wikigreen",
            image: "wikigreen.jpg",
            alt: "wikigreen",
            height: "200px",
            width: "200px",
            link: "/"
        }

        
    ])

    const [partenrsFifthCol, setPartnersFifthCol] = useState([
        
        {
            nom: "iscl",
            image: "iscl.JPG",
            alt: "iscl",
            height: "150px",
            width: "200px", 
            link: "https://www.iscl.fr"
        }, 
        
        {
            nom: "elhona",
            image: "ELHONA.png",
            alt: "elhona",
            height: "150px",
            width: "200px", 
            link: "https://www.elhona.com"
        }, 

        {
            nom: "nord isere",
            image: "nordisere.jpg",
            alt: "nord isere",
            height: "150px",
            width: "200px", 
            link: "https://www.facebook.com/Nord-Isere-3D-Environnement-213075155699338/"
        }, 

        {
            nom: "hary securité",
            image: "hary.jpg",
            alt: "hary securité",
            height: "150px",
            width: "200px", 
            link: "/"
        }, 


    ])


    


    var firstColToShow = partnersFirstCol.map((partner, i) => {
        return (
            <div style={{ marginBottom: 20 }}>
                <a href={partner.link} target="_blank"><img src={partner.image} alt={partner.alt} height={partner.height} width={partner.width} /></a>
            </div>
        )
    })

    var secondColToShow = partnersSecondCol.map((partner, i) => {
        return (
            <div style={{ marginBottom: 20 }}>
                <a href={partner.link} target="_blank"><img src={partner.image} alt={partner.alt} height={partner.height} width={partner.width} /></a>
            </div>
        )
    })

    var thirdColToShow = partnersThirdCol.map((partner, i) => {
        return (
            <div style={{ marginBottom: 20 }}>
                <a href={partner.link} target="_blank"><img src={partner.image} alt={partner.alt} height={partner.height} width={partner.width} /></a>
            </div>
        )
    })

    var fourthColToShow = partenrsFourthCol.map((partner, i) => {
        return (
            <div style={{ marginBottom: 20 }}>
                <a href={partner.link} target="_blank"><img src={partner.image} alt={partner.alt} height={partner.height} width={partner.width} /></a>
            </div>
        )
    })

    var fifthColToShow = partenrsFifthCol.map((partner, i) => {
        return (
            <div style={{ marginBottom: 20 }}>
                <a href={partner.link} target="_blank"><img src={partner.image} alt={partner.alt} height={partner.height} width={partner.width} /></a>
            </div>
        )
    })

    var partners = [
        firstColToShow,
        secondColToShow,
        thirdColToShow,
        fourthColToShow,
        fifthColToShow
    ]

    var partnersToShow = partners.map((partner, i) => {
        return (
            <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
                    {partner}

                </Col>
        )
    })

    return (
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "white", width: "100%", height: "100vh", margin: 0 }}>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <p style={{ color: "black", fontSize: 20 }}>Voici nos différents partenaires.</p>
                    <p style={{ color: "black", fontSize: 20 }}>Cliquez sur le logo pour avoir plus de détails.</p>
                </Col>
            </Row>

            <Row style={{ marginTop: 50, borderBottomStyle: "solid", borderBottomWidth: 2, borderColor: "white" }}>

                {partnersToShow}
                

            </Row>

            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>

        </Container>

    )
}

export default Partenaires