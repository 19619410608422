import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';


function Header() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);




  return (
    <div className="header" style={{ display: 'flex', flexDirection: "column", justifyContent: "center", backgroundColor: "black" }}>
      <Link to="/home">
        <div className="titre" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <div className="titre-1" style={{ marginRight: 10 }}>i38 infos</div>
          <div className="titre-2">WEB TV</div>
        </div>
      </Link>

      <div className="sous-titre">
        <div style={{ color: 'white', fontSize: 25, textAlign: "center" }}>On vous donne la Parole !</div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}>
        <Navbar color="black" dark expand="md">
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar style={{ fontSize: 20 }}>
              <NavItem>
                <Link to="/home">
                  <NavLink style={{ color: "white", marginRight: 50 }}>Accueil</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/sports" ><NavLink style={{ color: "white", marginRight: 50 }}>Sports </NavLink></Link>
              </NavItem>
              <NavItem>
                <Link to="/debats"><NavLink style={{ color: "white", marginRight: 50 }}>Débats</NavLink></Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle style={{ color: "white", marginRight: 50 }} nav caret>
                  Actus
              </DropdownToggle>
                <DropdownMenu right>
                  <Link to="/politique"><DropdownItem>
                    Politique
                </DropdownItem>
                  </Link>
                  <Link to="/national">
                    <DropdownItem>
                      National
                </DropdownItem>
                  </Link>
                  <Link to="/international">
                    <DropdownItem >
                      International
                </DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Link to="/partenaires">
                  <NavLink style={{ color: "white", marginRight: 50 }}>Partenaires</NavLink>
                </Link>
              </NavItem>

            </Nav>
            {/* <div>
              <input placeholder="Rechercher..."></input>
              <FontAwesomeIcon icon={faSearch} style={{ color: "white", marginLeft: 10 }} />
            </div> */}
          </Collapse>
        </Navbar>
      </div>

      {/* <div className="menu" style={{display: 'flex', flexDirection: "row", justifyContent: "space-around", color: "white"}}>
                <div><a href="/home">Accueil</a></div>
                <div><a href="/sports">Sport</a></div>
                <div><a href="#">Actu</a></div>
                <div><a href="#">Loisirs</a></div>
                <div><a href="#">Divers</a></div>
                <div><a href="#">Live</a></div>
                <div>
                    <input style={{marginRight: 10}} placeholder="Rechercher un article..."></input>
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div> */}

    </div>


  )
}

export default Header